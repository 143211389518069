.container{
    width: 100%;
    height: 15vh;
    display: flex;
    padding: 20px;
    position: fixed;
    background-color: black;
}

.logo{
    height: 100%;
    width: 12%;
    object-fit: cover;
    cursor: pointer;
    z-index: 1000000;
}

.container h1{
    font-family: 'Kanit';
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    background-color: transparent;
}