.container{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.content{
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    position: relative;
    overflow: hidden;
}

.carouselImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    opacity: 0.6;
}

.carouselText{
    font-family: 'Kanit';
    z-index: 100000;
    padding: 50px;
    width: 60%;
    transition: all 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.carousel {
    padding: 50px;
}