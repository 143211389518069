.container{
    position: fixed;
    right: 0;
    height: 100%;
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 1000000;
}

.container::after{
    content: ' ';
    width: 1px;
    height: 60%;
    background-color: #616161;
    align-self: center;
    opacity: 0.2;
}

.container:hover::after{
    opacity: 1;
}

.optionsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    height: 60%;
    justify-content: space-between;
    background-color: transparent;
}

.option{
    border: 1px solid #616161;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: black;
    opacity: 0.2;
}

.option p{
    display: none;
    font-size: 10px;
    font-family: 'Kanit';
    font-weight: 500;
}

.option:hover{
    border: 1px solid #F5F5F5;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    z-index: 10000;
    opacity: 1;
}

.optionActive{
    opacity: 1;
}

.option:hover p{
    display: flex;
    align-self: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.pageName:hover{
    display: block;
}