*{
    box-sizing: border-box;
    color: white;
    padding: 0;
    margin: 0;
}

body{
    overflow-y: hidden;
    background-color: black;
}